import { makeStyles } from '@material-ui/core/styles'

const formPadding = "5rem" // 80px
const formWidth = "35.625rem" // 570px
const inputBg = "#FFF"
const inputMargin= "1.5rem" // 24px
const useStyles = makeStyles( theme => ({
    root:{
        padding:formPadding,
        background:theme.palette.primary.light,
        minWidth:formWidth,
        maxWidth:formWidth,
        [theme.breakpoints.down("md")]: {
            maxWidth:'100%',
            minWidth:0,
            padding:10, 
        }
    },
    nestedInputWrapper:{
        [theme.breakpoints.down("md")]: {
            display:'flex',
            flexDirection:'column',
            minWidth:'100%',
        }
    },
    nestedInput:{
        [theme.breakpoints.down("md")]: {
            minWidth:'100%',
            marginBottom:inputMargin
        }
    },
    inputWrapper:{
        marginBottom:inputMargin,
    },
    inputPlaceholder:{
        marginBottom:'.75rem'
    },
    baseInput:{
        background:inputBg,
        minWidth:'100%',
        borderRadius:'5px',
        padding:'.5rem 1rem',
        boxShadow:'0px 5px 10px rgba(0, 0, 0, 0.02)',
        '& input':{
            fontWeight:'normal',
        },
        '& textarea':{
            fontWeight:'normal',
        },
    },
    selectInput:{
        '& .MuiFormLabel-root':{
            paddingLeft:'1rem',
        }
    },
    sendMessageBtn:{
        minHeight:'3rem',
        textTransform:'capitalize',
        color:'#FFF',
        borderRadius:'65px'
    }
    
}))
export default useStyles