import React from 'react'
import PropTypes from 'prop-types'
import { Box, Typography } from '@material-ui/core'
import useStyles from './style'

import ContactHeadingIcon from '../../images/contact-heading-icon.svg'
const ContactContent = props => {
    const classes = useStyles()
    const { heading, caption, children, maxWidth } = props
    
    return (
        <Box className={classes.root} style={{maxWidth:maxWidth}}>
            <Box className={classes.contentHeading} mb="32px">
                <Box mb="16px">
                    <img src={ContactHeadingIcon} alt="contact-heading-icon"/>
                </Box>
                <Box mb="16px">
                    <Typography variant="h2" className={classes.heading}>
                        { heading }
                    </Typography>
                </Box>
                <Box mb="16px">
                    <Typography variant="body2">
                        { caption }
                    </Typography>
                </Box>
            </Box>
            <Box className={classes.contentBody}>
                { children }
            </Box>
        </Box>
    )
}

export default ContactContent

ContactContent.propType = {
    heading:PropTypes.string,
    caption:PropTypes.string,
    maxWidth:PropTypes.string,
}