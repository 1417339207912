import { makeStyles } from '@material-ui/core/styles'

// const contentWidth = "28.438rem" // 455px
const useStyles = makeStyles( theme => ({
    root:{
        display:'flex',
        flexDirection:'column',
        padding:'80px 100px',
        [theme.breakpoints.down("md")]: {
            padding:0,
        }
    },

    heading:{
        [theme.breakpoints.down("md")]: {
            fontSize:'30px'
        }
    }
}))
export default useStyles